body{
	background-color:#FFF;
    font-family: "Lato", sans-serif;
    font-weight: 300;
	font-size:16px;
	line-height:18px;
	color:#777;
}

p{
	margin:0;
}

a {
    color: #aa0a5f;
}

a, a:hover, a:focus{
	text-decoration: none;
	outline: 0;
}
h1, h2, h3,
h4, h5, h6 {
    color: #333;
	margin:0;
    font-weight: normal;
}

h1 { font-size: 28px;}
h2 { font-size: 24px;}
h3 { font-size: 18px;}
h5 { font-size: 16px;}
h6 { font-size: 14px;}

strong {
  letter-spacing: 1px;
}

code {
  background-color: #ddd;
  border-radius: 3px;
  color: #000;
  font-size: 85%;
  margin: 0;
  padding: 5px 10px;
}

code a {
    color: #333;
}

code a:hover {
    text-decoration: underline;
}

section p {
  line-height: 28px;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    *zoom: 1;
}

.tan {
  margin-bottom: 10px;
}

.fifteen{
    margin-bottom: 15px;
}

.twenty{
    margin-bottom: 20px;
}

.center {
    text-align: center;
}

.title {
    margin: 50px 0 30px;
}

.syntaxhighlighter {
    border: 1px solid #efefef;
    max-height: 100% !important;
    padding: 20px 0;
}


.main-content section {
  margin: 0 5%;
}


.left-sidebar {
    background-color: #eb592d;
    float: left;
    min-height: 100%;
    position: fixed;
    width: 18%;
}
.left-sidebar-hr {
    background-color: #1A99F4;
    float: left;
    min-height: 100%;
    position: absolute;
    width: 18%;
}

.logo {
    padding-bottom: 30px;
    padding-left: 30px;
    padding-top: 70px;
}
.logo h1{
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
}
.left-nav ul {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.left-nav ul li a {
    color: #fff;
    display: block;
    padding: 10px 35px;
    -webkit-transition: all 0.3s ease-in 0s;
       -moz-transition: all 0.3s ease-in 0s;
        -ms-transition: all 0.3s ease-in 0s;
         -o-transition: all 0.3s ease-in 0s;
            transition: all 0.3s ease-in 0s;
}

.left-nav ul li a:hover, .left-nav ul li .current {
    background-color: #fff;
    color: #000;
}


#main-wrapper {
    float: left;
    margin-left: 18%;
    width: 82%;
}

.content-header {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    margin-top: 30px;
    padding: 30px 0 35px;
    text-align: center;
}

.welcome {
    font-size: 16px;
    line-height: 26px;
    margin: 35px auto 0;
}

.features {
    margin-top: 50px;
}

.features ul li {
    list-style: square outside none;
    margin-bottom: 15px;
}

.features > ul {
    padding-left: 18px;
}

.author {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    margin-top: 50px;
    padding: 30px 0;
}

.author-info {
    font-size: 18px;
    line-height: 28px;
    margin: 0 auto;
    width: 50%;
}

.section-content {
    font-size: 16px;
    line-height: 25px;
}

.section-content li {
    margin-bottom: 15px;
}

.section-content a:hover {
    text-decoration: underline;
}

.script-source li {
    list-style: square outside none;
    margin-bottom: 10px;
}

#twitter-feed li,
#flickr li {
    line-height: 25px;
    margin-bottom: 10px;
}

#twitter-feed img {
    border: 1px solid #ddd;
    box-shadow: 2px 3px 3px #ddd;
    height: auto;
    margin-top: 10px;
    max-width: 100%;
}













